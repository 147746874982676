import React, {useState} from "react";
import logo from "../assets/img/sign.png";
import {useNavigate} from "react-router-dom";
import {checkKey} from "../services/PWAService";
import {toast} from "react-toastify";
import NProgress from "nprogress";

export default function Login() {
    const [key, setKey] = useState("");
    const [connect, setConnect] = useState("Se connecter");
    const navigate = useNavigate();
    const handleSubmit = () => {
        if (key === '') {
            return;
        }

        NProgress.start();
        setConnect('Connexion en cours')
        checkKey(key).then(res => {
            if (res.data.user === null) {
                toast.error('Clé unique utilisateur invalide', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                localStorage.setItem('userKey', key);
                navigate('/');
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            NProgress.done();
            setConnect('Se connecter');
        })
    }

    return (
        <div className="container">
            <div className='col-12 col-md-4 ms-auto me-auto my-5'>
                <div className="card">
                    <div className="card-body">
                        <div className='text-center'>
                            <img src={logo} alt='logo' className='img-fluid w-25'/>
                            <h3>Espace abonné</h3>
                        </div>
                        <hr/>
                        <div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Identifiant unique</label>
                                <input type="text" className="form-control" value={key}
                                       onChange={e => setKey(e.target.value)}/>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary" onClick={handleSubmit}
                                        disabled={connect === 'Connexion en cours'}>{connect}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}