import axios from "axios";

const checkKey = (key: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/check-key`, {
        uuid: key
    })
}

const deleteUser = (key: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/delete`, {
        uuid: key
    })
}

export {checkKey, deleteUser}