import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {checkKey, deleteUser} from "../services/PWAService";
import logo from "../assets/img/sign.png";
import {disconnect} from "../helpers/utils";

export default function Main() {
    const [user, setUser] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const userKey = localStorage.getItem('userKey')
        if (userKey === null){
            navigate('/login');
        }else{
            checkKey(userKey).then(res => {
                if (res.data.user === null) {
                    navigate('/login');
                }else{
                    setUser(res.data.user);
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
            })
        }
    }, [])

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} className="img-fluid" style={{
                            maxHeight: "50px",
                        }} alt="logo"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Accueil</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/history'>Historique d'alarme</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={(e) =>{
                                    disconnect();
                                    navigate('/login');
                                }}>Se deconnecter</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container-fluid mt-3">
                <div className='card'>
                    <div className="card-body">
                        {Object.keys(user).length > 0 && (
                            <div>
                                <div>
                                    <h3>Bienvenue, {user.last_name.toUpperCase()}</h3>
                                    <p>
                                        Nombre d'alarme disponible : {user.alarm.number}
                                    </p>
                                </div>
                                <div className='float-end'>
                                    {user.status !== 5 ? (
                                        <button className='btn btn-danger' onClick={(event) => {
                                            // eslint-disable-next-line no-restricted-globals
                                            if (confirm("Voulez vous vraiment resilier votre abonnement ?")) {
                                                deleteUser(user.id).then(res => {
                                                }).catch(err => {
                                                    console.log(err);
                                                }).finally(() => {
                                                    navigate('/login')
                                                })
                                            }
                                        }}>Resilier mon abonnement</button>
                                    ):(
                                        <div>Votre abonnement a déjà été resilié</div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}