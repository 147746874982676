import logo from "../assets/img/sign.png";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {checkKey} from "../services/PWAService";
import {disconnect, formatDate} from "../helpers/utils";

export default function History(){
    const [user, setUser] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const userKey = localStorage.getItem('userKey')
        if (userKey === null){
            navigate('/login');
        }else{
            checkKey(userKey).then(res => {
                if (res.data.user === null) {
                    navigate('/login');
                }else{
                    setUser(res.data.user);
                    console.log(res.data.user);
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
            })
        }
    }, [])
    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="img-fluid" style={{
                            maxHeight: "50px",
                        }} alt="logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/">Accueil</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="#">Historique d'alarme</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={(e) =>{
                                    disconnect();
                                    navigate('/login');
                                }}>Se deconnecter</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container-fluid mt-3">
                {Object.keys(user).length > 0 && (
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {user.logs.map((item:any) => (
                            <tr key={item.id}>
                                <td>{formatDate(item.created_at)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}