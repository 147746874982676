function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    };

    return date.toLocaleDateString('fr-FR', options);
}

function disconnect() {
    localStorage.removeItem('userKey');
}

export {formatDate, disconnect};