import React from 'react';
import './App.css';
import {HashRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/Main";
import Login from "./pages/Login";
import {ToastContainer} from "react-toastify";
import History from "./pages/History";

function App() {
  return (
    <HashRouter>
        <ToastContainer/>
      <Routes>
        <Route path='/' element={<Main/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/history' element={<History/>}/>
      </Routes>
    </HashRouter>
  );
}

export default App;
